<template>
  <!-- <el-dialog
      title="模板发送人员管理"
      :visible.sync="show"
      :modal-append-to-body="false"
      center
      top="5vh"
      width="1000px"
      :before-close="handleClose"> -->
  <div class="account-user">
    <div class="flex-between">
      <el-button type="primary" @click="toEdit(null)">新增发送人员</el-button>
      <el-input
        placeholder="请输入姓名"
        v-model="search"
        style="width: 200px"
        clearable
        @keydown.enter.native="getList"
      >
        <el-button
          slot="append"
          icon="el-icon-search"
          @click="getList"
        ></el-button>
      </el-input>
      <el-input
        placeholder="请输入openid"
        v-model="openid"
        style="width: 200px"
        clearable
        @keydown.enter.native="getList"
      >
        <el-button
          slot="append"
          icon="el-icon-search"
          @click="getList"
        ></el-button>
      </el-input>
      <el-select
        v-model="type"
        @change="getList"
        style="width: 120px"
        clearable
      >
        <el-option :value="1" label="账号异常"></el-option>
        <!--        <el-option :value="2" label="微信未登录"></el-option>-->
      </el-select>
      <el-select
        v-model="templateId"
        placeholder="请选择模板"
        style="width: 120px"
        @change="getList"
        clearable
      >
        <el-option
          v-for="item in templateList"
          :key="item.id"
          :label="item.templateName"
          :value="item.id"
        >
        </el-option>
      </el-select>
    </div>
    <el-table
      v-loading="loading"
      :data="dataList"
      max-height="900"
      border
      style="width: 100%;margin-top: 15px"
    >
      <el-table-column prop="id" label="ID" width="80"> </el-table-column>
      <el-table-column width="120" prop="name" label="姓名"> </el-table-column>
      <el-table-column prop="openid" label="openid"> </el-table-column>
      <el-table-column prop="type" width="100" label="类型">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.type === 1" type="warning">账号异常</el-tag>
          <el-tag v-else-if="scope.row.type === 2" type="danger"
            >微信未登录</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column prop="templateName" label="模板"> </el-table-column>
      <el-table-column width="150" prop="action" label="操作">
        <template slot-scope="scope">
          <el-button class='button-small' type='primary' @click="toEdit(scope.row)">编辑</el-button>
          <el-button class='button-small' type="danger" @click="handleDelete(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <span slot="footer" class="dialog-footer">
      <el-button size="large" @click="handleClose">关 闭</el-button>
    </span>
    <el-drawer
      :title="`${editForm.id ? '修改' : '新增'}模板发送人员`"
      :visible.sync="isEdit"
      :append-to-body="true"
      size="550px"
    >
      <!-- <el-dialog
        :title="`${editForm.id ? '修改':'新增'}模板发送人员`"
        :visible.sync="isEdit"
        width="450px"
        append-to-body
        :before-close="()=>this.isEdit = false"> -->
      <el-form label-width="70px">
        <el-form-item label="分类">
          <el-select v-model="editForm.type" style="width: 100%">
            <el-option :value="1" label="账号异常"></el-option>
            <!--            <el-option :value="2" label="微信未登录"></el-option>-->
          </el-select>
        </el-form-item>
        <el-form-item label="发送模板">
          <el-select
            v-model="editForm.tmpId"
            placeholder="请选择模板"
            style="width: 100%"
          >
            <el-option
              v-for="item in templateList"
              :key="item.id"
              :label="item.templateName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="姓名">
          <el-input v-model="editForm.name" />
        </el-form-item>
        <el-form-item label="openid">
          <el-input v-model="editForm.openid" />
        </el-form-item>
      </el-form>
      <div class="dialog-footer">
        <el-button @click="isEdit = false">取 消</el-button>
        <el-button type="primary" @click="handleSubmitEdit">确 定</el-button>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import {
  getTemplateUser,
  editTemplateUser,
  deleteTemplateUser,
  getTemplateList
} from '@/api/account'

export default {
  name: 'user',
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      search: null,
      openid: null,
      dataList: [],
      page: 1,
      total: 0,
      loading: false,
      isEdit: false,
      editForm: {},
      templateList: [],
      templateId: null,
      type: null
    }
  },
  mounted () {
    getTemplateList().then(res => {
      this.templateList = res
    })
  },
  watch: {
    // show (newVal) {
    //   if (newVal) {
    //     this.getList()
    //   }
    // }
    show: {
      handler (newVal) {
        if (newVal) {
          this.getList()
        }
      },
      immediate: true
    }
  },
  methods: {
    handleSubmitEdit () {
      const { name, openid, tmpId, type } = this.editForm
      if (!type) {
        this.$message.warning('请选择类型')
        return false
      }
      if (!tmpId) {
        this.$message.warning('请选择发送模板')
        return false
      }
      if (!name) {
        this.$message.warning('请输入姓名')
        return false
      }
      if (!openid) {
        this.$message.warning('请输入openid')
        return false
      }
      editTemplateUser(this.editForm).then(() => {
        this.getList()
        this.isEdit = false
      })
    },
    toEdit (data) {
      this.isEdit = true
      if (data) {
        this.editForm = {
          id: data.id,
          name: data.name,
          openid: data.openid,
          tmpId: data.tmpId,
          type: data.type
        }
      } else {
        this.editForm = {
          type: 1
        }
      }
    },
    handleDelete (data) {
      this.$confirm('此操作将删除模板发送人员, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          deleteTemplateUser(data.id).then(() => {
            this.getList()
          })
        })
        .catch(() => {})
    },
    getList () {
      this.loading = true
      getTemplateUser({
        name: this.search,
        openid: this.openid,
        type: this.type,
        templateId: this.templateId
      })
        .then(res => {
          this.dataList = res
          this.total = res.total
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleClose () {
      this.search = ''
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-drawer {
  padding: 20px;
  /deep/ .el-drawer__header {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
  }
}
.account-user {
  width: 1000px;
  background: #fff;
  box-sizing: border-box;
  padding: 20px;
}
.dialog-footer {
  display: inline-block;
  width: 100%;
  text-align: center;
  margin-top: 20px;
}
</style>
