<template>
  <div>
    <div
      class="
        part
        border-radius-6
        box-shadow-light-grey
        padding-20
        height-calc-type1
      "
    >
      <div
        class="flex-between operation padding-bottom-20"
        style="background: #fff"
      >
        <div class="row">
          <el-button @click="getList">刷新数据</el-button>
          <el-select
            class="margin-right-twentyFour margin-left-twentyFour"
            placeholder="平台"
            clearable
            v-model="platform"
            @change="getList"
          >
            <el-option
              v-for="(item, index) in platformList"
              :label="item"
              :value="index"
              :key="index"
            >
            </el-option>
          </el-select>
          <el-input
            placeholder="用户名"
            v-model="search"
            class="input-with-select"
            @keydown.enter.native="getList"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="getList"
            ></el-button>
          </el-input>
        </div>
        <div>
          <el-popover placement="top-end" width="250" trigger="click">
            <div class="flex-between">
              <div class="platform-box" @click="handleAccountLogin(1, null)">
                阅 文
              </div>
              <div class="platform-box" @click="handleAccountLogin(2, null)">
                追书云
              </div>
              <div class="platform-box" @click="handleAccountLogin(7, null)">
                花生书城
              </div>
              <div class="platform-box" @click="toEdit(null)">其 他</div>
            </div>
            <el-button slot="reference" type="primary">新增账号</el-button>
          </el-popover>
          <el-button
            type="warning"
            style="margin-left: 20px"
            @click="showUserDialog = true"
            >模板发送人员</el-button
          >
        </div>
      </div>
      <el-table
        v-loading="loading"
        :data="dataList"
        height="calc(100vh - 300px)"
        style="width: 100%"
      >
        <el-table-column prop="id" label="ID" width="100"> </el-table-column>
        <el-table-column prop="uname" min-width="120" label="用户名">
        </el-table-column>
        <el-table-column min-width="100" prop="platformStr" label="平台">
        </el-table-column>
        <el-table-column prop="profitRate" min-width="80" label="利率">
        </el-table-column>
        <el-table-column min-width="80" prop="statusStr" label="登录状态">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.status === 0" type="success">已登录</el-tag>
            <el-tag v-else-if="scope.row.status === 1" type="warning"
              >密码错误</el-tag
            >
            <el-tag v-else type="danger">登录过期</el-tag>
          </template>
        </el-table-column>
        <el-table-column min-width="180" prop="createdTime" label="创建时间">
        </el-table-column>
        <el-table-column label="操作" width="400px">
          <template slot-scope="scope">
            <el-button
              class="button-small"
              type="warning"
              @click="handleProfitSet(scope.row)"
              >设置利率</el-button
            >
            <el-button class="button-small" @click="handleDialogShow(scope.row)"
              >公众号列表</el-button
            >
            <template>
              <el-button
                class="button-small"
                type="primary"
                @click="toEdit(scope.row)"
                v-if="
                  scope.row.platform !== 1 &&
                  scope.row.platform !== 2 &&
                  scope.row.platform !== 7
                "
                >编辑
              </el-button>
              <el-button
                class="button-small"
                type="info"
                @click="handleHuashengReLogin(scope.row)"
                v-if="scope.row.platform == 9 && scope.row.status !== 0"
                >重新登录
              </el-button>
              <!-- 七悦重登 -->
              <!-- <el-button
                class="button-small"
                type="info"
                @click="handleShowQiyueReLogin(scope.row)"
                v-if="scope.row.platform == 6 && scope.row.status !== 0"
                >重新登录
              </el-button> -->
              <el-button
                class="button-small"
                type="info"
                @click="handleAccountLogin(scope.row.platform, scope.row)"
                v-else-if="scope.row.status !== 0"
                >重新登录
              </el-button>
            </template>
            <el-button
              class="button-small"
              type="danger"
              @click="deleteItem(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        style="margin-top: 10px; text-align: right"
        background
        @current-change="handlePageChange"
        :current-page="page"
        :page-size="15"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <el-drawer
      :title="form.id ? '编辑账号' : '添加账号'"
      :visible.sync="isEdit"
      size="500px"
      direction="rtl"
    >
      <el-form label-width="100px">
        <el-form-item label="平台">
          <el-select
            clearable
            v-model="form.platform"
            style="width: 80%"
            @change="handlePlatformChange"
          >
            <el-option
              v-for="(item, index) in platformList"
              :label="item"
              :value="index"
              :disabled="index === 1 || index === 2 || index === 7"
              :key="index"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="用户名">
          <el-input v-model="form.uname" style="width: 80%"></el-input>
        </el-form-item>
        <el-form-item label="密码">
          <el-input
            type="password"
            show-password
            v-model="form.password"
            style="width: 80%"
          ></el-input>
        </el-form-item>
        <el-form-item label="验证码" v-if="form.platform === 9">
          <el-input
            v-model="huashengValidCode"
            style="width: 225px; margin-right: 10px"
            placeholder="请输入手机验证码"
          ></el-input>
          <el-button
            type="primary"
            @click="getPhoneNumber"
            :loading="phoneButtonLoading"
            size="small"
            class="button-small"
            style="padding-top: 8px !important; padding-bottom: 8px !important"
            :disabled="isSendPhoneCode === true"
            >获取验证码<span v-if="countDown !== 0"
              >({{ countDown }})</span
            ></el-button
          >
        </el-form-item>
        <!-- 七悦独有选项 -->
        <template v-if="form.platform === 6">
          <el-form-item label="验证码">
            <div class="qiyue-ver">
              <el-input v-model="captcha" style="width: 200px"></el-input>
              <img
                @click="handleGetQiyueCode"
                style="margin-left: 10px"
                :src="qiyueImg"
                alt=""
              />
            </div>
          </el-form-item>
        </template>
        <!-- 花生书城独有选项 -->
        <template v-if="form.platform === 7">
          <el-form-item label="apiKey">
            <el-input v-model="apiKey" style="width: 80%"></el-input>
          </el-form-item>
          <el-form-item label="apiSecurity">
            <el-input v-model="apiSecurity" style="width: 80%"></el-input>
          </el-form-item>
        </template>
        <!-- 针对掌读书城 -->
        <template v-if="form.platform === 10">
          <el-form-item label="vipId">
            <el-input v-model="vipId" style="width: 80%"></el-input>
          </el-form-item>
          <el-form-item label="appSecret">
            <el-input v-model="appSecret" style="width: 80%"></el-input>
          </el-form-item>
        </template>
      </el-form>
      <div class="dialog-footer">
        <el-button @click="isEdit = false">取 消</el-button>
        <el-button
          type="primary"
          v-if="form.platform !== 9"
          :loading="loadingButton"
          @click="handleSubmit"
          >确 定</el-button
        >
        <el-button
          v-else
          type="primary"
          :loading="loadingButton"
          @click="handleXuFengSubmit"
          >确 定</el-button
        >
      </div>
    </el-drawer>
    <el-drawer
      :visible.sync="showUserDialog"
      direction="rtl"
      size="1000px"
      title="模板发送人员"
      :append-to-body="true"
    >
      <template-user :show="showUserDialog" @close="showUserDialog = false" />
    </el-drawer>
    <el-drawer
      :visible.sync="showAccountDialog"
      direction="rtl"
      size="800px"
      title="公众号列表"
    >
      <account-list
        :show="showAccountDialog"
        :account-id="selectAccountId"
        :platform-id="selectPlatform"
        @close="showAccountDialog = false"
      />
    </el-drawer>
    <!-- 花生书城验证码 -->
    <el-dialog
      :visible.sync="showhuasheng"
      :append-to-body="true"
      width="300px"
    >
      <div class="login-box">
        <div class="code-img-box">
          <el-card style="width: 100%">
            <div slot="header" class="clearfix">
              <div>
                <img :src="huashengImg" alt="" />
              </div>
              <span>请输入登录验证码</span>
            </div>
            <el-input
              :placeholder="`请输入${
                targetPlatform === 9 ? '手机验证码' : '验证码'
              }`"
              v-model="huashengValidCode"
            ></el-input>
            <el-button
              style="margin-top: 20px"
              @click="showhuasheng = !showhuasheng"
              >取消</el-button
            >
            <el-button
              type="primary"
              style="margin-top: 20px"
              :disabled="!huashengValidCode"
              @click="handleHuashengSubmit"
              >提交</el-button
            >
          </el-card>
        </div>
      </div>
    </el-dialog>
    <!-- 七悦重登 -->
    <el-dialog
      :visible.sync="showQiyueReLogin"
      :append-to-body="true"
      destroy-on-close
      width="300px"
    >
      <div class="login-box">
        <div class="code-img-box">
          <el-card style="width: 100%">
            <div slot="header" class="clearfix">
              <div>
                <img @click="handleGetQiyueCode" :src="qiyueImg" alt="" />
              </div>
            </div>
            <el-input
              :placeholder="`请输入验证码`"
              v-model="captcha"
            ></el-input>
            <el-button
              style="margin-top: 20px"
              @click="showQiyueReLogin = false"
              >取消</el-button
            >
            <el-button
              type="primary"
              style="margin-top: 20px"
              :disabled="!captcha"
              @click="handleQiyueReLogin"
              >提交</el-button
            >
          </el-card>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getAccountList,
  deleteAccount,
  editAccount,
  setAccountProfit,
  getAccountPeanutToken,
  getAccountPeanutCode,
} from "@/api/account";
import { platformList } from "@/assets/js/options";
import TemplateUser from "./user";
import AccountList from "./accountList";
import { clearCookie } from "@/assets/js/utils";
export default {
  name: "Account",
  data() {
    return {
      search: null,
      platform: null,
      loading: false,
      page: 1,
      total: 0,
      dataList: [],
      isEdit: false,
      form: {},
      showUserDialog: false,
      showAccountDialog: false,
      selectAccountId: null,
      selectPlatform: null,
      platformList,
      captcha: null,
      showQiyueReLogin: false,
      currentAccount: {},
      // 新增花生书城
      apiKey: null,
      apiSecurity: null,
      showhuasheng: false,
      huashengLoading: false,
      huashengValidCode: null,
      huashengImg: null,
      huashengKey: null,
      huashengSubType: null,
      huashengAccountsId: null,
      // 提交加载按钮
      loadingButton: false,
      secretKey: null, //绪风书城secretKey
      iv: null, // 绪风书城iv
      targetPlatform: null,
      appSecret: null,
      phoneButtonLoading: false,
      vipId: null,
      countDown: 0, // 绪风书城验证码
      isSendPhoneCode: false, // 发送验证码是否成功
      qiyueImg: "",
      qiyueCookies: "",
    };
  },
  mounted() {
    this.getList();
  },
  watch: {
    isSendPhoneCode: {
      handler(newV) {
        if (newV) {
          this.countDown = 60;
          this.handleCountDown();
        } else {
          this.countDown = 0;
        }
      },
    },
  },
  methods: {
    handleGetQiyueCode() {
      getAccountPeanutCode({
        platform: 6,
      }).then((res) => {
        this.qiyueImg = res.base64Code;
        this.qiyueCookies = res.cookies;
      });
    },
    // 七悦重登
    handleShowQiyueReLogin(data) {
      this.qiyueImg = "";
      this.qiyueCookies = "";
      this.currentAccount = data;
      this.showQiyueReLogin = true;
      this.captcha = "";
      this.handleGetQiyueCode();
    },
    handleQiyueReLogin() {
      getAccountPeanutToken({
        accountsId: this.currentAccount.id,
        code: this.captcha,
        secretKey: this.qiyueCookies,
      }).then((res) => {
        this.showQiyueReLogin = false;
        this.$message.success("重新登录成功");
        this.getList();
      });
    },
    // 花生重新登录
    handleHuashengReLogin(data) {
      // 获取验证码
      this.targetPlatform = data.platform;
      this.huashengAccountsId = data.id;
      this.huashengSubType = 2;
      getAccountPeanutCode({
        // type: data.platform,
        uname: data.uname,
      }).then((res) => {
        if (data.platform === 7) {
          this.huashengKey = res.cookie;
          this.huashengValidCode = res.code;
          this.handleHuashengSubmit();
          // this.huashengImg = res.img;
          // this.huashengKey = res.key;
        }
        if (data.platform === 9) {
          let map = Object.keys(res.map);
          this.secretKey = map[0];
          this.iv = res.map[this.secretKey];
          // this.huashengImg = res.codeImg;
          this.huashengKey = res.codeEncrypt;
          this.huashengValidCode = null;
          this.showhuasheng = true;
        }
      });
    },
    // 处理countDown
    handleCountDown() {
      let timer;
      timer = setInterval(() => {
        this.countDown--;
        if (this.countDown === 0) {
          clearInterval(timer);
          timer = null;
          this.isSendPhoneCode = false;
        }
      }, 1000);
    },
    // 绪风书城输入验证码提交

    handleXuFengSubmit() {
      let tmpParams = {};
      if (this.huashengSubType === 1) {
        tmpParams = {
          uname: this.form.uname,
          password: this.form.password,
          key: this.huashengKey,
          code: this.huashengValidCode,
          type: 9,
          secretKey: this.secretKey,
          iv: this.iv,
        };
      } else {
        tmpParams = {
          accountsId: this.huashengAccountsId,
          key: this.huashengKey,
          code: this.huashengValidCode,
          type: this.targetPlatform,
          secretKey: this.secretKey,
          iv: this.iv,
          // password:this.form.password,
          // uname:this.form.uname,
        };
      }
      this.loadingButton = true;
      getAccountPeanutToken(tmpParams)
        .then((res) => {
          if (res.msg) {
            this.$message.error(res.msg);
            return false;
          }
          // 重新登录
          if (this.huashengSubType === 2) {
            this.$message.success("重新登录成功");
            this.getList();
            this.isEdit = false;
          } else {
            editAccount({ ...this.form, userToken: res }).then(() => {
              this.$message.success("保存成功!");
              this.getList();
              this.isEdit = false;
            });
          }
        })
        .finally(() => {
          this.loadingButton = false;
        });
    },

    // 花生验证码提交
    handleHuashengSubmit() {
      // this.showhuasheng = false;
      let tmpParams = {};
      // 新增修改
      // 花生
      if (this.targetPlatform === 7) {
        if (this.huashengSubType === 1) {
          tmpParams = {
            uname: this.form.uname,
            password: this.form.password,
            key: this.huashengKey,
            code: this.huashengValidCode,
            type: this.targetPlatform,
          };
        } else {
          tmpParams = {
            accountsId: this.huashengAccountsId,
            key: this.huashengKey,
            code: this.huashengValidCode,
            type: this.targetPlatform,
          };
        }
        // 绪风书城
      } else if (this.targetPlatform === 9) {
        if (this.huashengSubType === 1) {
          tmpParams = {
            uname: this.form.uname,
            password: this.form.password,
            key: this.huashengKey,
            code: this.huashengValidCode,
            type: this.targetPlatform,
            secretKey: this.secretKey,
            iv: this.iv,
          };
        } else {
          tmpParams = {
            accountsId: this.huashengAccountsId,
            key: this.huashengKey,
            code: this.huashengValidCode,
            type: this.targetPlatform,
            secretKey: this.secretKey,
            iv: this.iv,
          };
        }
      }
      getAccountPeanutToken(tmpParams)
        .then((res) => {
          if (res.msg) {
            this.$message.error(res.msg);
            this.showhuasheng = false;
            return false;
          }
          // 重新登录
          if (this.huashengSubType === 2) {
            this.$message.success("重新登录成功");
            this.loadingButton = false;
            this.getList();
          } else {
            editAccount({ ...this.form, userToken: res })
              .then(() => {
                this.$message.success("保存成功");
                this.getList();
                this.isEdit = false;
              })
              .finally(() => {
                this.loadingButton = false;
              });
          }
        })
        .finally(() => {
          this.showhuasheng = false;
        });
    },

    // 平台切换监听
    handlePlatformChange(value) {
      if (value === 6) {
        this.handleGetQiyueCode();
      }
    },
    handleProfitSet(item) {
      this.$prompt("请输入利率", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputType: "number",
        inputValue: item.profitRate,
      })
        .then(({ value }) => {
          if (value && value > 0) {
            setAccountProfit(item.id, value).then(() => {
              this.$message.success("设置成功");
              this.getList();
            });
          } else {
            this.$message.error("操作失败，请输入利率");
          }
        })
        .catch(() => {});
    },
    toEdit(data) {
      if (data) {
        this.form = {
          uname: data.uname,
          password: "",
          id: data.id,
          platform: data.platform,
        };
        if (data.platform === 7) {
          this.apiKey = data.apiKey;
          this.apiSecurity = data.apiSecurity;
        }
        if (data.platform === 6) {
          this.handleGetQiyueCode();
        }
      } else {
        this.form = {};
        this.apiKey = null;
        this.apiSecurity = null;
      }
      this.huashengSubType = 1;
      this.isEdit = true;
    },
    handleAccountLogin(platform, account) {
      const setting =
        "height=610, width=1100, top=150, left=" +
        (window.innerWidth - 1100) / 2 +
        ",toolbar=no, menubar=no, scrollbars=no, resizable=no,location=no, status=no";

      if (platform === 1) {
        clearCookie("https://open.yuewen.com")
          .then(() => {
            window.open(
              `https://open.yuewen.com/new/login?name=${
                account ? account.uname : ""
              }`,
              "newYueWen",
              setting
            );
          })
          .catch(() => {
            this.$message.error("清除cookie失败，请重试");
          });
      } else if (platform === 2) {
        clearCookie("https://channel.zhuishuyun.com")
          .then(() => {
            window.open(
              `https://channel.zhuishuyun.com/passport/login?name=${
                account ? account.uname : ""
              }`,
              "newZhuiShuYun",
              setting
            );
          })
          .catch(() => {
            this.$message.error("清除cookie失败，请重试");
          });

      } else if (platform === 6) {
        clearCookie('https://vip.zhangwenwenhua.com')
          .then(()=>{
            window.open(`https://vip.zhangwenwenhua.com/admin/index/login/accountName=${account?account.uname : ''}`,'newQiYue',setting)
          }).catch(()=>{
            this.$message.error('清除cookie失败，请重试!')
          })
      } else if (platform === 7) {
        // 花生书城
        clearCookie("http://vip.rlcps.cn")
          .then(() => {
            if (account) {
              window.open(
                `http://vip.rlcps.cn/#/user/login/accountName=${
                  account ? account.uname : ""
                }`,
                "newHuaSheng",
                setting
              );
            } else {
              window.open(
                `http://vip.rlcps.cn/#/user/login`,
                "newHuaSheng",
                setting
              );
            }
          })
          .catch(() => {
            this.$message.error("清除cookie失败，请重试");
          });
      }
    },
    // 针对绪风书城
    getPhoneNumber() {
      this.phoneButtonLoading = true;
      getAccountPeanutCode({
        uname: this.form.uname,
      })
        .then((res) => {
          this.$message.success("已发送验证码！");
          let map = Object.keys(res.map);
          this.secretKey = map[0];
          this.iv = res.map[this.secretKey];
          this.huashengKey = res.codeEncrypt;
          this.isSendPhoneCode = true;
        })
        .finally(() => {
          this.phoneButtonLoading = false;
        });
    },
    handleSubmit() {
      const { uname, password, platform, id } = this.form;
      this.targetPlatform = platform;
      if (!id && typeof platform !== "number") {
        this.$message.error("请选择平台");
        return false;
      }
      if (!uname) {
        this.$message.error("请输入账号");
        return false;
      }
      if (!password) {
        this.$message.error("请输入密码");
        return false;
      }
      if (platform === 10) {
        if (!this.vipId) {
          this.$message.error("请输入vipId");
          return false;
        }
        if (!this.appSecret) {
          this.$message.error("请输入appSecret");
          return false;
        }
      }
      if (platform === 6) {
        if (!this.captcha) {
          this.$message.error("请输入验证码");
          return false;
        }
      }
      if (platform === 7) {
        if (!this.apiKey) {
          this.$message.error("请输入apiKey");
          return false;
        } else if (!this.apiSecurity) {
          this.$message.error("请输入apiSecurity");
          return false;
        } else {
          this.form.reqData = JSON.stringify({
            apiKey: this.apiKey,
            apiSecurity: this.apiSecurity,
          });
          // 获取验证码
          this.huashengSubType = 1;
          this.loadingButton = true;
          getAccountPeanutCode()
            .then((res) => {
              this.huashengKey = res.cookie;
              this.huashengValidCode = res.code;
              this.handleHuashengSubmit();
              // 获取花生token
            })
            .catch(() => {
              this.loadingButton = false;
            });
        }
      } else {
        //绪风书城
        if (platform === 9) {
          this.huashengSubType = 1;
          getAccountPeanutCode({ uname })
            .then((res) => {
              //  this.huashengImg =
              let map = Object.keys(res.map);
              this.secretKey = map[0];
              this.iv = res.map[this.secretKey];
              this.huashengImg = res.codeImg;
              this.huashengKey = res.codeEncrypt;
              this.huashengValidCode = null;
              this.showhuasheng = true;
            })
            .finally(() => {
              this.loadingButton = false;
            });
        } else {
          let obj = JSON.parse(JSON.stringify(this.form));
          if (platform === 10) {
            obj = {
              ...obj,
              reqData: {
                vipId: this.vipId,
                appSecret: this.appSecret,
              },
            };
          }
          if (platform === 6) {
            obj = {
              ...obj,
              userToken: this.qiyueCookies,
              captcha: this.captcha,
            };
          }
          this.loadingButton = true;
          editAccount(obj)
            .then(() => {
              this.$message.success("保存成功");
              this.getList();
              this.isEdit = false;
            })
            .finally(() => {
              this.loadingButton = false;
            });
        }
      }
    },
    deleteItem(data) {
      this.$confirm("此操作将永久删除该平台账号, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteAccount(data.id).then(() => {
            this.getList();
          });
        })
        .catch(() => {});
    },
    handleDialogShow(item) {
      this.selectAccountId = item.id;
      this.selectPlatform = item.platform;
      this.showAccountDialog = true;
    },
    getList() {
      this.loading = true;
      getAccountList({
        page: this.page,
        pageSize: 15,
        uname: this.search,
        platform: this.platform,
      })
        .then((res) => {
          this.dataList = res.slice((this.page - 1) * 15, this.page * 15);
          this.total = res.length;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handlePageChange(page) {
      this.page = page;
      this.getList();
    },
  },
  components: {
    TemplateUser,
    AccountList,
  },
};
</script>

<style lang="scss" scoped>
.input-with-select {
  width: 200px;
}

.platform-box {
  width: 60px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  background: #1c9399;
  margin-bottom: 10px;
  color: #fff;
  border-radius: 50%;
  cursor: pointer;
  &:last-child {
    margin-bottom: 0;
  }
}
.part {
  background: #fff;
}
.operation {
  background: #fff;
  // padding: 0 15px;
  // box-shadow: -2px -2px 10px rgba(180, 180, 180, 0.1);
}
/deep/ .el-drawer {
  /deep/ .el-drawer__header {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
  }
}
.dialog-footer {
  margin-top: 40px;
  text-align: center;
}
// 花生验证码
.code-img-box {
  width: 300px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.code-img-box img {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
}

// 七悦验证
.qiyue-ver {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
</style>
