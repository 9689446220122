<template>
  <!-- <el-dialog
      title="公众号列表"
      :visible.sync="show"
      width="800px"
      top="10vh"
      center
      :before-close="handleClose"> -->
  <div class="account-list">
    <div class="flex-between">
      <div>
        <el-button @click="getList">刷新数据</el-button>
        <el-button @click="handleSynAccountChannel">公众号同步</el-button>
        <!--        <el-button :disabled="selected.length===0" type="warning" @click="isSet=true">批量修改状态</el-button>-->
      </div>
      <div>
        <el-input
          placeholder="公众号名称"
          v-model="channelName"
          style="width: 180px"
          @keydown.enter.native="getList"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="getList"
          ></el-button>
        </el-input>
        <el-tag style="margin-left: 20px" size="default"
          >总数：{{ dataList.length }}</el-tag
        >
      </div>
    </div>
    <el-table
      v-loading="loading"
      ref="multipleTable"
      :data="dataList"
      height="650px"
      style="width: 100%; margin-top: 15px"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column prop="id" label="ID" width="120"> </el-table-column>
      <el-table-column prop="channelName" label="公众号名称"> </el-table-column>
      <el-table-column prop="flag" label="微信未登录提醒">
        <template slot-scope="scope">
          <el-switch
            @change="handleSwitchChange(scope.row, 'flag')"
            v-model="scope.row.flag"
            :active-value="1"
            :inactive-value="0"
            active-color="#00bf8a"
            inactive-color="#fd5d5a"
          >
          </el-switch>
          <span style="margin-left: 5px">{{
            scope.row.flag === 0 ? "关闭" : "开启"
          }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="flag" label="同步消耗">
        <template slot-scope="scope">
          <el-switch
            @change="handleSwitchChange(scope.row, 'syncCost')"
            v-model="scope.row.syncCost"
            :active-value="1"
            :inactive-value="0"
            active-color="#00bf8a"
            inactive-color="#fd5d5a"
          >
          </el-switch>
          <span style="margin-left: 5px">{{
            scope.row.syncCost === 0 ? "关闭" : "开启"
          }}</span>
        </template>
      </el-table-column>
    </el-table>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">关 闭</el-button>
    </span>
  </div>
</template>

<script>
import {
  getAccountOfficial,
  setSendStatus,
  synAccountChannel,
} from "@/api/account";

export default {
  name: "accountList",
  props: {
    show: Boolean,
    accountId: Number,
    platformId: Number,
  },
  data() {
    return {
      dataList: [],
      loading: false,
      selected: [],
      isSet: false,
      form: {
        flag: 1,
        syncCost: 1,
      },
      channelName: "",
    };
  },
  watch: {
    // show(newVal) {
    //   if (newVal) {
    //     this.getList();
    //   }
    // }
    show: {
      handler(newVal) {
        if (newVal) {
          this.getList();
        }
      },
      immediate: true,
    },
  },
  methods: {
    // 同步公众号
    handleSynAccountChannel() {
      synAccountChannel({
        platform: this.platformId,
        accountId: this.accountId,
      }).then((res) => {
        this.$message.success("同步成功");
        this.getList();
      });
    },
    getList() {
      this.loading = true;
      getAccountOfficial(this.accountId, this.channelName)
        .then((res) => {
          this.dataList = res;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleSelectionChange(row) {
      this.selected = row.map((item) => item.id);
    },
    handleSwitchChange(data, prop) {
      const params = {
        [prop]: data[prop],
      };
      setSendStatus(data.id, params).then(() => {
        this.$message.success("设置成功");
        this.getList();
      });
    },
    handleClose() {
      this.$emit("close");
    },
  },
};
</script>

<style lang='scss' scoped>
.account-list {
  width: 800px;
  box-sizing: border-box;
  padding: 20px;
}
.dialog-footer {
  display: inline-block;
  width: 100%;
  text-align: center;
  margin-top: 20px;
}
</style>
